
































































import { Vue, Component } from "vue-property-decorator";
import axios from "axios";
import { ActivityGroup } from "@/types";
import tools from "@/helpers/tools";

@Component({
  metaInfo() {
    return {
      title: "Faaliyet Grupları / Admin"
    };
  }
})
export default class AdminActivityGroupsView extends Vue {
  search = "";
  loading = false;
  headers = [
    {
      text: "Id",
      align: "start",
      filterable: true,
      value: "id"
    },
    { text: "Başlık", value: "title" },
    { text: "Açıklama", value: "description" },
    { text: "", value: "actions", sortable: false, width: "50px" }
  ];
  items = [] as ActivityGroup[];

  async getItems() {
    try {
      this.loading = true;

      var res = await axios.get("/api/activitygroup/get-all", {
        headers: this.$store.getters["auth/requestAuthHeader"]
      });

      this.items = res.data;
    } catch (e: unknown) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `Faaliyet grupları alınamadı.`
      });
    } finally {
      this.loading = false;
    }
  }

  async deleteItem(activityGroup: ActivityGroup) {
    const confirmation = await this.$confirm("Bu işlem geri alınamaz. Faaliyet grubunu silmek istediğinizden emin misiniz?", { title: "Emin misiniz?" });

    if (!confirmation) return;

    try {
      console.log(activityGroup);

      // Etkinlik grubunu sil
      const authHeader = this.$store.getters["auth/requestAuthHeader"];
      var res = await axios.delete(`/api/activitygroup/${activityGroup.id}`, {
        headers: authHeader
      });

      this.$notify({
        type: "success",
        title: "İşlem Tamam",
        text: "Faaliyet grubu başarıyla silindi."
      });

      await this.getItems();
    } catch (e) {
      this.$notify({
        type: "eror",
        title: "Hata!",
        text: "Faaliyet grubu silinemedi."
      });

      throw e;
    }
  }

  async mounted() {
    await this.getItems();
  }

  formatDesc(html: string) {
    return html.length > 100 ? tools.clearHtmlTags(html).substring(0, 100) + "..."
      : tools.clearHtmlTags(html);
  }
}
